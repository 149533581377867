import { Button, Col, Drawer, Flex, Grid, Row, Table, Typography, Tag, Spin, Card, Statistic, TableProps, } from "antd";
import { SyncOutlined, EyeOutlined, } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { useAuth, useSupabase } from "../../AuthProvider";
import { AnyObject } from "antd/es/_util/type";
import { useParams } from "react-router-dom";
import Chart from "react-apexcharts";

const { useBreakpoint } = Grid;

function EvaluationView() {
    let { id } = useParams();
    const { profile } = useAuth();
    const supabase = useSupabase();
    const [evaluation, setEvaluation] = useState<AnyObject>();
    const [progress, setProgress] = useState<number>(0.0);
    const [userResults, setUserResults] = useState<AnyObject[]>([]);
    const [open, setOpen] = useState(false);
    const [drawerTitle, setDrawerTitle] = useState('');
    const screens = useBreakpoint();

    const DrawerContent = () => {
        const [loading, setLoading] = useState(true);
        const [userScore, setUserScore] = useState<number>(0);
        const [userScoreDetails, setUserScoreDetails] = useState<AnyObject>({});

        useEffect(() => {
            const score_communication = (userResults.reduce((accumulator, currentValue) => accumulator + currentValue.score_communication, 0) / userResults.length);
            const score_collaboration = (userResults.reduce((accumulator, currentValue) => accumulator + currentValue.score_collaboration, 0) / userResults.length);
            const score_work_quality = (userResults.reduce((accumulator, currentValue) => accumulator + currentValue.score_work_quality, 0) / userResults.length);
            const score_growth = (userResults.reduce((accumulator, currentValue) => accumulator + currentValue.score_growth, 0) / userResults.length);
            const score_teamwork = (userResults.reduce((accumulator, currentValue) => accumulator + currentValue.score_teamwork, 0) / userResults.length);

            setUserScoreDetails({
                score_communication,
                score_collaboration,
                score_work_quality,
                score_growth,
                score_teamwork,
            })

            setUserScore(score_communication + score_collaboration + score_work_quality + score_growth + score_teamwork)

            setLoading(false);
        }, []);

        const expandedRowRender = (record: AnyObject) => {
            return <Table
                columns={[
                    { title: 'Category', dataIndex: 'category' },
                    { title: 'Score', dataIndex: 'score' },
                ]}
                dataSource={[
                    {
                        key: 'Communication',
                        category: 'Communication',
                        score: record.score_communication,
                    },
                    {
                        key: 'Collaboration',
                        category: 'Collaboration',
                        score: record.score_collaboration,
                    },
                    {
                        key: 'Quality of Work',
                        category: 'Quality of Work',
                        score: record.score_work_quality,
                    },
                    {
                        key: 'Personal Growth',
                        category: 'Personal Growth',
                        score: record.score_growth,
                    },
                    {
                        key: 'Teamwork / Team Player',
                        category: 'Teamwork / Team Player',
                        score: record.score_teamwork,
                    }
                ]}
                pagination={false}
                size="small"
            />;
        }

        if (!userScoreDetails || loading) return <Spin />;

        return (
            <Row gutter={[24, 24]}>
                <Col xs={24} lg={8}>
                    <Card>
                        <Statistic
                            title="Overall Score"
                            value={(userScore / 25) * 100}
                            precision={2}
                            suffix="%"
                        />
                        <Typography.Text type="secondary">{userScore} / 25</Typography.Text>
                    </Card>
                </Col>
                <Col xs={24} lg={16}>
                    <Chart
                        options={{
                            chart: {
                                id: "user evaluation",
                            },
                            xaxis: {
                                categories: [
                                    'Collaboration',
                                    'Communication',
                                    'Growth',
                                    'Teamwork',
                                    'Quality of Work'
                                ]
                            },
                            yaxis: {
                                max: 5,
                                stepSize: 1
                            }
                        }}
                        series={[
                            {
                                name: "Score",
                                data: [
                                    userScoreDetails.score_collaboration,
                                    userScoreDetails.score_communication,
                                    userScoreDetails.score_growth,
                                    userScoreDetails.score_teamwork,
                                    userScoreDetails.score_work_quality,
                                ]
                            }
                        ]}
                        type="radar"
                    />
                </Col>
                <Col span={24}>
                    <Table
                        expandable={{
                            expandedRowRender: (record) => expandedRowRender(record),
                            defaultExpandedRowKeys: ['0']
                        }}
                        pagination={false}
                        dataSource={userResults}
                        columns={[
                            {
                                title: 'Evaluator',
                                dataIndex: 'evaluator_email',
                                render: (_, record) => (
                                    <>
                                        <Typography.Text strong>{record.eval_invitations.name}</Typography.Text><br />
                                        <Typography.Text type="secondary">{record.eval_invitations.email}</Typography.Text>
                                    </>
                                ),
                            },
                            {
                                title: 'Score',
                                dataIndex: 'score',
                                render: (_, record) => {
                                    const totalScore = record?.score_collaboration
                                        + record?.score_communication
                                        + record?.score_growth
                                        + record?.score_teamwork
                                        + record?.score_work_quality;

                                    return (
                                        <>
                                            <Typography.Text>
                                                {(totalScore) / 25 * 100}%
                                            </Typography.Text> <Typography.Text type="secondary">
                                                {totalScore}/25
                                            </Typography.Text>
                                        </>
                                    )
                                },
                            },
                        ]}
                    />
                </Col>
            </Row>
        )

    }

    const toggleDrawer = () => {
        setOpen(!open);
    };

    useEffect(() => {
        if (id) fetchEvaluations(id);
    }, []);

    const fetchEvaluations = async (id: string) => {
        try {
            const { data } = await supabase
                .from('evaluations')
                .select(`
                    *,
                    eval_invitations(*),
                    eval_results(
                        *,
                        eval_invitations(*)
                    )
                `)
                .eq('id', id)
                .single();
            if (data) {
                setEvaluation(data)
                setProgress(data.eval_invitations.filter((item: any) => (item.responded_at)).length / data.eval_invitations.length * 100);
            };
        } catch (error) {
            console.error(error);
        }
    }

    const loadResults = (email: string, name: string) => {
        setDrawerTitle(`Results for ${name}`);
        const results = evaluation?.eval_results.filter((item: any) => (item.evaluatee_email === email));
        setUserResults(results.map((item: AnyObject) => ({ ...item, key: item.id })));
        toggleDrawer();
    }

    if (!evaluation) return <Flex justify="center"><Spin /></Flex>;

    const getColumns = () => {
        const fullColumns: TableProps<AnyObject>['columns'] = [
            {
                title: 'Email',
                dataIndex: 'email',
                render: (_, record) => (
                    <>
                        <Typography.Text strong>{record.name}</Typography.Text><br />
                        <Typography.Text type="secondary">{record.email}</Typography.Text>
                    </>
                )
            },
            {
                title: 'Status',
                dataIndex: 'status',
                render: (_, record) => (
                    <Tag
                        icon={record.responded_at ? <></> : <SyncOutlined spin />}
                        color={record.responded_at ? 'success' : ''}
                    >
                        {record.responded_at ? 'Completed' : 'Awaiting Responses'}
                    </Tag>
                )
            },
            {
                title: 'Results',
                dataIndex: 'results',
                render: (_, record) => (
                    <Button
                        size="small"
                        icon={<EyeOutlined />}
                        type={(progress === 100) ? 'primary' : 'default'}
                        onClick={() => loadResults(record.email, record.name)}
                    >
                        {`View${(progress === 100) ? ' ' : ' Partial '}Results`}
                    </Button>
                )
            },
        ]

        const mobileColumns: TableProps<AnyObject>['columns'] = [
            {
                title: 'User',
                dataIndex: 'email',
                render: (_, record) => (
                    <>
                        <Flex align="center" justify="space-between" wrap>
                            <Typography.Text strong>
                                {record.name}
                            </Typography.Text><br />
                            <Tag
                                icon={record.responded_at ? <></> : <SyncOutlined spin />}
                                color={record.responded_at ? 'success' : ''}
                            >
                                {record.responded_at ? 'Completed' : 'Awaiting Responses'}
                            </Tag>
                        </Flex>
                        <Typography.Text type="secondary">{record.email}</Typography.Text>
                        <br />
                        <Button
                            size="small"
                            icon={<EyeOutlined />}
                            type={(progress === 100) ? 'primary' : 'default'}
                            onClick={() => loadResults(record.email, record.name)}
                        >
                            {`View${(progress === 100) ? ' ' : ' Partial '}Results`}
                        </Button>
                    </>
                )
            },
        ]

        return screens.lg ? fullColumns : mobileColumns;
    }

    return (
        <>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Flex align="center"  >
                        <Typography.Title level={3}>{evaluation?.name}:</Typography.Title>
                    </Flex>
                </Col>
                <Col xs={24} lg={4}>
                    <Card>
                        <Statistic
                            title="Completion Progress"
                            value={progress}
                            precision={0}
                            suffix="%"
                        />
                    </Card>
                </Col>
                <Col span={24}>
                    <Card title='Invitees'>
                        <Table
                            pagination={false}
                            dataSource={evaluation.eval_invitations.map((item: AnyObject) => ({ ...item, key: item.id }))}
                            columns={getColumns()}
                        />
                    </Card>
                </Col>
            </Row>
            <Drawer
                title={drawerTitle}
                onClose={toggleDrawer}
                open={open}
                size="large"
                destroyOnClose
            >
                <DrawerContent />
            </Drawer>
        </>
    )

}

export default EvaluationView;