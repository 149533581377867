import { Card, Col, Row, Table, Typography } from "antd";

function Metrics() {
    return (
        <>
            <Typography.Title>Metrics</Typography.Title>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Card title='Your Evaluation Scores'>
                        <Table>

                        </Table>
                    </Card>
                </Col>
                <Col span={24}>
                    <Card title='Completed Evaluations'>
                        <Table>

                        </Table>
                    </Card>
                </Col>
            </Row>
        </>
    )

}

export default Metrics;
