import React from'react';
import { Route, Routes } from 'react-router-dom';
import Dashboard from './pages/dashboard/Dashboard';
import BaseLayout from './pages/dashboard/_base';
import Metrics from './pages/dashboard/Metrics';
import Evaluations from './pages/dashboard/Evaluations';
import Account from './pages/dashboard/Account';
import Evaluation from './pages/evaluation';
import Login from './pages/Login';
import { RequireAuth } from './AuthProvider';
import EvaluationView from './pages/dashboard/EvaluationView';

function App() {
  return (
    <Routes>
      <Route element={<RequireAuth><BaseLayout /></RequireAuth>}>
        <Route path="" element={<Dashboard />} />
        <Route path="*" element={<Dashboard />} />
        <Route path="your-metrics" element={<Metrics />} />
        <Route path="evaluations" element={<Evaluations />} />
        <Route path="evaluations/:id" element={<EvaluationView />} />
        <Route path="account" element={<Account />} />
      </Route>
      <Route path="evaluation">
        <Route path=":id" element={<Evaluation />} />
      </Route>
      <Route path="login" element={<Login />} />
    </Routes>
  );
}

export default App;
