import { Alert, Button, Card, Col, Empty, Row, Table, Typography } from "antd";
import { useAuth, useSupabase } from '../../AuthProvider';
import { useEffect, useState } from "react";
import { AnyObject } from "antd/es/_util/type";
import { useNavigate } from "react-router-dom";

function Dashboard() {
    let navigate = useNavigate();
    const { profile, user } = useAuth();
    const supabase = useSupabase();
    const [invite, setInvite] = useState<AnyObject>();

    async function checkForInvites() {
        await supabase
            .from('eval_invitations')
            .select()
            .eq('email', user.email)
            .is('responded_at', null)
            .limit(1)
            .maybeSingle()
            .then(({ data, error }) => {
                if (error) {
                    console.error(error);
                } else if (data) {
                    setInvite(data);
                }
            });
    }

    useEffect(() => {
        checkForInvites();
    }, [])

    return (
        <>
            <Typography.Title>Welcome {profile.name}</Typography.Title>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    {invite
                        ? <Alert
                            message="You've been invited to complete an evaluation!"
                            type="warning"
                            action={
                                <Button size="small" type="default" onClick={() => navigate(`/evaluation/${invite.evaluation_id}`)}>
                                    Complete Evaluation
                                </Button>
                            }
                        />
                        : <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={
                                <Typography.Text>
                                    Looks like you're all caught up!<br />
                                    If you're invited to complete an evaluation, it'll be highlighted here.
                                </Typography.Text>
                            }
                        />
                    }
                </Col>
            </Row>
        </>
    )

}

export default Dashboard;
