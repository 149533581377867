import { Button, Card, Col, Divider, Flex, Form, Image, Modal, Progress, Result, Row, Slider, Spin, Typography } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth, useSupabase } from "../../AuthProvider";
import { useEffect, useState } from "react";
import { AnyObject } from "antd/es/_util/type";
import Login from "../Login";

function Evaluation() {
    let { id } = useParams();
    let navigate = useNavigate();

    const supabase = useSupabase();
    const { user } = useAuth();
    const [status, setStatus] = useState('loading');
    const [submitting, setSubmitting] = useState(false);
    const [pendingEvaluations, setPendingEvaluations] = useState<AnyObject[]>([]);
    const [currentEvaluation, setCurrentEvaluation] = useState<AnyObject>();
    const [currentIndex, setCurrentIndex] = useState<number>(-1);
    const [invitation, setInvitation] = useState<AnyObject>();
    const [form] = Form.useForm();

    async function checkInvitation() {
        setStatus('loading');
        const { data, error } = await supabase
            .from('eval_invitations')
            .select()
            .eq('evaluation_id', id)
            .eq('email', user.email)
            // .eq('responded_at', null)
            .maybeSingle();

        if (error) {
            console.error('You are not invited to this evaluation.');
            setStatus('invalid');
        } else if (data) {
            setInvitation(data);
            fetchEvaluation();
        }
    }

    async function fetchEvaluation() {
        const { data, error } = await supabase
            .from('evaluations')
            .select(`
                *,
                eval_invitations(*),
                eval_results(*)
            `)
            .eq('id', id)
            .eq('eval_results.evaluator_id', user.id)
            .single();

        if (error) {
            console.error(error);
            setStatus('invalid');
        } else if (data) {
            setPendingEvaluations(data.eval_invitations.filter((invitation: AnyObject) => (invitation.email !== user.email && !data.eval_results.some((item: AnyObject) => item.evaluatee_email === invitation.email))));
            setCurrentIndex(0);
            setStatus('in-progress');
        }

    }

    async function submitEvaluation() {
        setSubmitting(true);
        const evalResults = form.getFieldsValue();

        await supabase
            .from('eval_results')
            .insert({
                evaluator_id: user.id,
                evaluatee_email: currentEvaluation?.email,
                evaluation_id: id,
                invitation_id: invitation?.id,
                ...evalResults,
            })
            .then(({ data, error }) => {
                if (error) {
                    console.error('Error submitting evaluation:', error);
                } else {
                    if (currentIndex < (pendingEvaluations.length - 1)) {
                        setCurrentIndex(currentIndex + 1);
                    } else {
                        logResponse();
                    }
                    form.resetFields();
                }
            });

        setSubmitting(false);
    }

    async function logResponse() {
        await supabase
            .from('eval_invitations')
            .update({ responded_at: new Date() })
            .eq('id', invitation?.id)
            .then(({ data, error }) => {
                if (error) {
                    console.error('Error submitting evaluation:', error);
                } else {
                    setStatus('complete');
                }
            });
        setStatus('complete');
    }

    useEffect(() => {
        if (user && id) checkInvitation();
    }, [user]);

    // Set the current evaluation based on the current index.
    useEffect(() => {
        setCurrentEvaluation(pendingEvaluations[currentIndex]);
    }, [currentIndex]);

    const sliderProps = {
        min: 1,
        max: 5,
        marks: {
            1: ' ',
            2: ' ',
            3: ' ',
            4: ' ',
            5: ' ',
        },
        style: { margin: 0 }
    }

    // If not logged in, prompt the user to log in.
    if (!user) return <Modal
        open={!user}
        footer={false}
        closable={false}
    >
        <Login
            promptText="It looks like you've been invited to complete an evaluation! Pleas log in or create an account with the same email."
            redirectUrl={`/evaluation/${id}`}
        />
    </Modal>;

    if (status === 'loading') return <Spin />

    // If logged in and not invited to this evaluation, show error with button to redirect.
    if (status !== 'in-progress' || !currentEvaluation) {
        if (status === 'complete' || status === 'in-progress') {
            return <Result
                status="success"
                title="Successfully Completed Evaluation!"
                subTitle="You've completed all your evaluations at this time! Thank you."
                extra={[
                    <Button type="primary" key="console" onClick={() => navigate('/portal/dashboard')}>
                        Go to Dashboard
                    </Button>,
                ]}
            />
        } else {
            return <Result
                status="warning"
                title="We don't have a valid invite for you to complete this evaluation."
                subTitle="If you think this may be an error, please contact the creator of this evaluation."
                extra={[
                    <Button type="primary" key="console" onClick={() => navigate('/portal/dashboard')}>
                        Go to Dashboard
                    </Button>,
                ]}
            />
        }
    }

    // If logged in and invited, show evaluation form and progress bar.
    return (
        <Row
            justify={'center'}
            style={{
                padding: 20,
            }}
        >
            <Col xs={24} lg={16}>
                <Form form={form} initialValues={{
                    score_communication: 3,
                    score_collaboration: 3,
                    score_work_quality: 3,
                    score_growth: 3,
                    score_teamwork: 3
                }}>
                    <Flex justify="center">
                        <Image
                            src={'/imgs/teamgauge.png'}
                            preview={false}
                            style={{
                                maxWidth: 200,
                                paddingInline: 20
                            }}
                        />
                    </Flex>
                    <Divider />
                    <Row
                        justify={'center'}
                        gutter={[24, 24]}
                        style={{ padding: 20 }}
                    >
                        <Col span={24}>
                            <Flex align="center" justify="space-between">
                                <Typography.Title level={3} style={{ margin: 0 }}>Evaluating {currentEvaluation?.name}:</Typography.Title>
                                <Progress type={'circle'} percent={(currentIndex / pendingEvaluations.length) * 100} status="active" size={'small'} />
                            </Flex>
                            <Typography.Text type="secondary">
                                Please complete the evaluation below to the best of your ability. <br />
                                <strong>{currentEvaluation?.name}</strong> will not be able to see your responses, but the creator of this evaluation will.
                            </Typography.Text>
                        </Col>
                        <Col span={24}>
                            <Card>
                                <Row justify={'center'} align={'middle'} gutter={16}>
                                    <Col span={12}>
                                        <Typography.Title level={5} style={{ margin: 0 }}>Communication</Typography.Title>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name="score_communication" style={{ margin: 0 }}>
                                            <Slider {...sliderProps} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col span={24}>
                            <Card>
                                <Row justify={'center'} align={'middle'} gutter={16}>
                                    <Col span={12}>
                                        <Typography.Title level={5} style={{ margin: 0 }}>Collaboration</Typography.Title>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name="score_collaboration" style={{ margin: 0 }}>
                                            <Slider {...sliderProps} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col span={24}>
                            <Card>
                                <Row justify={'center'} align={'middle'} gutter={16}>
                                    <Col span={12}>
                                        <Typography.Title level={5} style={{ margin: 0 }}>Quality of Work</Typography.Title>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name="score_work_quality" style={{ margin: 0 }}>
                                            <Slider {...sliderProps} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col span={24}>
                            <Card>
                                <Row justify={'center'} align={'middle'} gutter={16}>
                                    <Col span={12}>
                                        <Typography.Title level={5} style={{ margin: 0 }}>Personal Growth</Typography.Title>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name="score_growth" style={{ margin: 0 }}>
                                            <Slider {...sliderProps} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col span={24}>
                            <Card>
                                <Row justify={'center'} align={'middle'} gutter={16}>
                                    <Col span={12}>
                                        <Typography.Title level={5} style={{ margin: 0 }}>Teamwork / Team Player</Typography.Title>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name="score_teamwork" style={{ margin: 0 }}>
                                            <Slider {...sliderProps} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col span={24}>
                            <Card size="small">
                                <Flex justify="flex-end">
                                    <Button
                                        type="primary"
                                        size="large"
                                        onClick={submitEvaluation}
                                        loading={submitting}
                                    >Submit</Button>
                                </Flex>
                            </Card>
                        </Col>
                    </Row>
                </Form>
            </Col >
        </Row >
    )

}

export default Evaluation;
