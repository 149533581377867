import { Button, Col, Divider, Drawer, Flex, Grid, Image, MenuProps, Row } from 'antd';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import {
    AppstoreOutlined,
    SnippetsOutlined,
    FundOutlined,
    UserOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined
} from '@ant-design/icons';
import { Menu } from 'antd';
import { useEffect, useState } from 'react';

const { useBreakpoint } = Grid;

function BaseLayout() {
    const { pathname } = useLocation();
    const [visible, setVisible] = useState(false);
    let path = pathname.split('/')[1];
    let navigate = useNavigate();
    const screens = useBreakpoint();

    const toggleDrawer = () => {
        setVisible(!visible);
    }

    const onClick: MenuProps['onClick'] = (e) => {
        toggleDrawer();
        navigate(`${e.key}`);
    };

    const MenuContent = () => (
        <>
            <Image
                width={'100%'}
                src={'/imgs/teamgauge.png'}
                preview={false}
                style={{ paddingInline: 20 }}
            />
            <Divider />
            <Menu
                style={{ height: '100%' }}
                defaultSelectedKeys={[path ? path : 'dashboard']}
                onClick={onClick}
                mode="inline"
                items={[
                    {
                        key: 'dashboard',
                        label: 'Dashboard',
                        icon: <AppstoreOutlined />,
                    },
                    // {
                    //     key: 'your-metrics',
                    //     label: 'Your Metrics',
                    //     icon: <FundOutlined />,
                    // },
                    {
                        key: 'evaluations',
                        label: 'Evaluations',
                        icon: <SnippetsOutlined />,
                    },
                    {
                        key: 'account',
                        label: 'Account',
                        icon: <UserOutlined />,
                    },
                ]}
            />
        </>
    )

    return (
        <Row
            justify={'center'}
            gutter={16}
            style={{ padding: 20 }}
        >
            {screens.lg
                ? <Col span={4}>
                    <MenuContent />
                </Col>
                : <>
                    <Flex align='center' justify='start' style={{ width: '100%' }}>
                        <Button
                            type="text"
                            icon={<MenuUnfoldOutlined />}
                            size={'large'}
                            style={{ marginBottom: 6 }}
                            onClick={toggleDrawer}
                        />
                        <Image
                            width={200}
                            src={'/imgs/teamgauge.png'}
                            preview={false}
                            style={{ paddingInline: 8, aspectRatio: 'initial' }}
                        />
                    </Flex>
                    <Divider style={{ margin: 8, padding: 0 }} />
                    <Drawer
                        // title="Basic Drawer"
                        placement={'left'}
                        // closable={false}
                        onClose={toggleDrawer}
                        open={visible}
                        closeIcon={<MenuFoldOutlined />}
                        // key={placement}
                    >
                        <MenuContent />
                    </Drawer>
                </>
            }
            <Col xs={24} lg={20}>
                <Outlet />
            </Col>
        </Row>
    );

}

export default BaseLayout;