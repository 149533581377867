import React, { useEffect, useState } from 'react';
import { Card, Flex, Button, Form, Input, Row, Col, Typography, Image } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../AuthProvider';
import { AnyObject } from 'antd/es/_util/type';

type FieldType = {
    email?: string;
    password?: string;
    name?: string;
};

const tabs = [
    {
        key: 'login',
        label: 'login',
    },
    {
        key: 'signup',
        label: 'sign up',
    },
];

interface LoginProps {
    promptText?: string
    redirectUrl?: string
}

const Login: React.FC<LoginProps> = ({
    promptText = 'TeamGauge makes it easy to collect evaluations for members of your team and keep track of their growth!',
    redirectUrl = '/portal/dashboard',
}) => {
    let [params] = useSearchParams();
    const [activeTab, setActiveTab] = useState<string>(`${params.get('type') === 'signup' ? 'signup' : 'login'}`);
    let navigate = useNavigate();
    let auth = useAuth();

    const changeTab = (key: string) => {
        setActiveTab(key);
    };

    useEffect(() => {
        if (auth.user && auth.profile && auth.session) {
            // Redirect already logged in.
            navigate(redirectUrl, { replace: true })
        }
    }, [])

    const content: Record<string, React.ReactNode> = {
        login: <Form
            name='login'
            onFinish={handleLogin}
            layout='vertical'
        >
            <Form.Item<FieldType>
                label="Email"
                name="email"
                rules={[{ required: true, message: 'Please input your email!' }]}
            >
                <Input />
            </Form.Item>

            <Form.Item<FieldType>
                label="Password"
                name="password"
                rules={[{ required: true, message: 'Please input your password!' }]}
            >
                <Input.Password />
            </Form.Item>

            <Form.Item>
                <Button type='primary' htmlType="submit">
                    Submit
                </Button>
            </Form.Item>
        </Form>,
        signup: <Form
            name='signup'
            onFinish={handleSignUp}
            layout='vertical'
        >
            <Form.Item<FieldType>
                label="Name"
                name="name"
            >
                <Input />
            </Form.Item>

            <Form.Item<FieldType>
                label="Email"
                name="email"
                rules={[{ required: true, message: 'Please input your email!' }]}
            >
                <Input />
            </Form.Item>

            <Form.Item<FieldType>
                label="Password"
                name="password"
                rules={[{ required: true, message: 'Please input your password!' }]}
            >
                <Input.Password />
            </Form.Item>

            <Form.Item>
                <Button type='primary' htmlType="submit">
                    Submit
                </Button>
            </Form.Item>
        </Form>,
    };

    function handleLogin(formData: AnyObject) {
        auth.signInWithEmail(formData, () => {
            navigate(redirectUrl, { replace: true });
        });
    }

    function handleSignUp(formData: AnyObject) {
        auth.signUpNewUser(formData, () => {
            navigate(redirectUrl, { replace: true });
        });
    }

    return (
        <Flex
            align='center'
            justify='center'
            style={{
                // minHeight: '100vh',
                // paddingInline: 48,
                maxWidth: 600,
                margin: 'auto',
                paddingBlockStart: 64
            }}
        >
            <Row align={'middle'} justify={'center'} gutter={[24, 24]}>
                <Col span={24} style={{ textAlign: 'center' }}>
                    <Image
                        src={'/imgs/teamgauge.png'}
                        preview={false}
                        style={{
                            maxWidth: 300,
                            paddingInline: 20
                        }}
                    />
                </Col>
                <Col span={24} style={{ textAlign: 'center' }}>
                    <Typography.Text type='secondary'>
                        Welcome to TeamGauge!<br />
                        {promptText}
                    </Typography.Text>
                </Col>
                <Col span={24}>
                    <Card
                        tabList={tabs}
                        activeTabKey={activeTab}
                        onTabChange={changeTab}
                        style={{ minWidth: 300 }}
                    >
                        {content[activeTab]}
                    </Card>
                </Col>
            </Row>
        </Flex>
    );
};

export default Login;