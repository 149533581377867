import { Button, Col, Drawer, Flex, Row, Table, Typography, Form, Input, Popconfirm, Space, Tag, Grid, Alert, Badge, } from "antd";
import { PlusOutlined, UploadOutlined, EyeOutlined, DeleteOutlined } from '@ant-design/icons';
import React, { useContext, useEffect, useRef, useState } from 'react';
import type { GetRef, InputRef } from 'antd';
import { useAuth, useSupabase } from "../../AuthProvider";
import { AnyObject } from "antd/es/_util/type";
import { useNavigate } from "react-router-dom";

type FormInstance<T> = GetRef<typeof Form<T>>;

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface Item {
    key: string;
    name: string;
    age: string;
    address: string;
}

interface EditableRowProps {
    index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

interface EditableCellProps {
    title: React.ReactNode;
    editable: boolean;
    dataIndex: keyof Item;
    record: Item;
    handleSave: (record: Item) => void;
}

const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef<InputRef>(null);
    const form = useContext(EditableContext)!;

    useEffect(() => {
        if (editing) {
            inputRef.current?.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    };

    const save = async () => {
        try {
            const values = await form.validateFields();

            toggleEdit();
            handleSave({ ...record, ...values });
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    let childNode = children;

    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{ margin: 0 }}
                name={dataIndex}
                rules={[
                    {
                        required: true,
                        message: `${title} is required.`,
                    },
                ]}
            >
                <Input ref={inputRef} onPressEnter={save} onBlur={save} />
            </Form.Item>
        ) : (
            <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
                {children}
            </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

interface DataType {
    key: React.Key;
    name: string;
    email: string;
}

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

function Evaluations() {
    let navigate = useNavigate();

    const { profile, updateProfile } = useAuth();
    const [open, setOpen] = useState(false);
    const [count, setCount] = useState(2);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const supabase = useSupabase();
    const [evaluations, setEvaluations] = useState<AnyObject[]>([]);
    const screens = Grid.useBreakpoint();

    const fetchEvaluations = async () => {
        setLoading(true);
        try {
            const { data } = await supabase
                .from('evaluations')
                .select('*, eval_invitations(*)')
                .eq('created_by', profile?.id)
                .order('created_at', { ascending: false });
            if (data) {
                setEvaluations(data.map((item) => ({ ...item, key: item.id })))
            };
        } catch (error) {
            console.error(error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchEvaluations();
    }, [])

    const toggleDrawer = () => {
        setOpen(!open);
    };

    const [invitees, setInvitees] = useState<DataType[]>([
        {
            key: '0',
            name: 'Edward King 0',
            email: 'email@example.com',
        },
        {
            key: '1',
            name: 'Edward King 1',
            email: 'email@example.com',
        },
    ]);

    const handleDelete = (key: React.Key) => {
        const newData = invitees.filter((item) => item.key !== key);
        setInvitees(newData);
    };

    const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
        {
            title: 'Name',
            dataIndex: 'name',
            editable: true,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            editable: true,
        },
        {
            title: '',
            dataIndex: 'operation',
            render: (_, record) =>
                invitees.length >= 1 ? (
                    <Popconfirm title="Are you sure?" onConfirm={() => handleDelete(record.key)}>
                        <DeleteOutlined />
                    </Popconfirm>
                ) : null,
        },
    ];

    const handleAdd = () => {
        const newData: DataType = {
            key: count,
            name: `click to edit`,
            email: `click to edit`,
        };
        setInvitees([...invitees, newData]);
        setCount(count + 1);
    };

    const handleSave = (row: DataType) => {
        const newData = [...invitees];
        const index = newData.findIndex((item) => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row,
        });
        setInvitees(newData);
    };

    async function createEvaluation() {
        await supabase
            .from('evaluations')
            .insert({
                name: form.getFieldValue('title'),
                created_by: profile.id,
            })
            .select()
            .single()
            .then(({ data, error }) => {
                if (error) {
                    setLoading(false);
                };
                if (data) {
                    createInvitations(data.id);
                };

            })
    }

    async function createInvitations(evaluation_id: number) {
        const consumedInvites = invitees.length;

        await supabase
            .from('eval_invitations')
            .insert(invitees.map((item) => ({
                email: item.email,
                name: item.name,
                evaluation_id: evaluation_id
            })));

        updateProfile({
            available_invites: profile.available_invites - consumedInvites,
        }).then(() => {
            fetchEvaluations().then(() => {
                toggleDrawer();
            });
        });

        await supabase
            .from('profiles')
            .update({
                available_invites: profile.available_invites - consumedInvites,
            })
            .eq('id', profile.id)
            .select()
            .then(({ data, error }) => {

            });
    }

    const saveEvaluation = () => {
        setLoading(true);
        form.validateFields()
            .catch((e) => { setLoading(false); })
            .then((values) => {
                if (values) {
                    createEvaluation();
                }
            });
    }

    const columns = defaultColumns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: DataType) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave,
            }),
        };
    });

    return (
        <>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Flex align="center" justify="space-between" >
                        <Typography.Title>Evaluations</Typography.Title>
                        <Button
                            type="primary"
                            onClick={toggleDrawer}
                        ><PlusOutlined />{screens.lg && 'New Evaluation'}
                    </Button>
                    </Flex>
                </Col>
                <Col span={24}>
                    <Table
                        dataSource={evaluations}
                        columns={[
                            {
                                title: '',
                                dataIndex: 'view',
                                render: (_, record) => (
                                    <Button type="link" onClick={() => navigate(`${record.id}`)}><EyeOutlined /></Button>
                                )
                            },
                            {
                                title: 'Name',
                                dataIndex: 'name',
                            },
                            {
                                title: 'Created',
                                dataIndex: 'created_at',
                                render: (text) => {
                                    const date = new Date(text);
                                    return date.toLocaleString();
                                }
                            },
                            {
                                title: 'Status',
                                dataIndex: 'name',
                                render: (_, record) => {
                                    const invites = record.eval_invitations.length;
                                    const completed = record.eval_invitations.filter((item: AnyObject) => (item.responded_at)).length;
                                    return (
                                        <>
                                            <Tag>{completed} / {invites}</Tag>
                                            <Tag color={(invites > completed) ? 'success' : 'default'}>{(invites > completed) ? 'Active' : 'Completed'}</Tag>
                                        </>
                                    )
                                }
                            },
                        ]}
                    />
                </Col>
            </Row >
            <Drawer
                title="Create an Evaluation"
                onClose={toggleDrawer}
                open={open}
                size="large"
                destroyOnClose
            >
                <Form layout="vertical" form={form} preserve={false}>
                    <Form.Item label="Title" name="title" rules={[{ required: true, message: 'Title is required!' }]}>
                        <Input placeholder="Quarterly Team Snapshot" />
                    </Form.Item>
                    <Space direction="vertical" size={'middle'}>
                        <Table
                            components={{
                                body: {
                                    row: EditableRow,
                                    cell: EditableCell,
                                },
                            }}
                            rowClassName={() => 'editable-row'}
                            bordered
                            dataSource={invitees}
                            columns={columns as ColumnTypes}
                            pagination={false}
                            footer={() =>
                                <Space>
                                    <Button size="small" onClick={handleAdd} type="primary">
                                        <PlusOutlined /> Add Invitee
                                    </Button>
                                </Space>
                            }
                        />
                        <Flex justify="end">
                            <Popconfirm
                                title="Are you sure to submit?"
                                description="Submitting this form will send invites out to each email."
                                onConfirm={saveEvaluation}
                            >
                                <Button type="primary" loading={loading}>
                                    Submit
                                </Button>
                            </Popconfirm>
                        </Flex>
                        <Typography.Text type="secondary">
                            Each invitee will be asked to evaluate each other person on the evaluation.
                            It's best to keep invitee lists small and only include people who may have worked together.
                        </Typography.Text>
                        <Typography.Text type="secondary">
                            For example, keep evaluations to a single team, or single shift, not the whole company at once.
                            Also, don't forget to include any superiors you may also want evaluated. It's helpful for employees to also
                            evaluate their supervisors and other direct superiors.
                        </Typography.Text>
                        <Badge.Ribbon text='Coming Soon'>
                            <Alert
                                message={'Scheduled Evaluations'}
                                description="Set a spend limit and TeamGauge will automatically schedule and send evalutations on your behalf. You'll be able to see growth over time, and receive regular reports in your email inbox."
                            />
                        </Badge.Ribbon>
                    </Space>
                </Form>
            </Drawer>
        </>
    )

}

export default Evaluations;