import { Alert, Badge, Button, Card, Col, Descriptions, DescriptionsProps, Drawer, Flex, Popover, Row, Space, Statistic, Table, Typography } from "antd";
import { useAuth, useSupabase } from "../../AuthProvider";
import { useEffect, useState } from "react";
import { AnyObject } from "antd/es/_util/type";
import { RocketOutlined, InfoCircleOutlined } from "@ant-design/icons";

function Account() {
    const { user, profile, signOut } = useAuth();
    const createdAt = new Date(profile.created_at);
    const supabase = useSupabase();
    const [completedEvaluations, setCompletedEvaluations] = useState<AnyObject[]>([]);
    const [open, setOpen] = useState(false);

    const toggleDrawer = () => {
        setOpen(!open);
    };

    async function getCompletedEvaluations() {
        // TODO: Add pagination.
        await supabase
            .from('eval_invitations')
            .select('*, evaluations(name)')
            .eq('email', user.email)
            .not('responded_at', 'is', null)
            .order('created_at', { ascending: false })
            .then(({ data, error }) => {
                if (error) {
                    console.error(error);
                } else {
                    setCompletedEvaluations(data.map((item) => ({ ...item, key: item.id })));
                }
            });
    }

    useEffect(() => {
        getCompletedEvaluations();
    }, [])

    const items: DescriptionsProps['items'] = [
        {
            key: '1',
            label: 'Name',
            children: profile.name,
        },
        {
            key: '2',
            label: 'Email',
            children: user.email,
        },
        {
            key: '3',
            label: 'Joined On',
            children: createdAt.toLocaleDateString(),
        },
    ];

    return (
        <>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Flex align="center" justify="space-between">
                        <Typography.Title>Account</Typography.Title>
                        <Button
                            danger
                            size="small"
                            onClick={() => signOut(() => {})}
                        >
                            Sign Out
                        </Button>
                    </Flex>
                </Col>
                <Col span={12}>
                    <Card>
                        <Descriptions
                            column={1}
                            items={items}
                            layout='vertical'
                            size="small"
                        />
                    </Card>
                </Col>
                <Col span={12}>
                    <Card>
                        <Statistic
                            title={<Flex align="center" justify="space-between" wrap>
                                <Popover content={'Each person you invite to complete an evaluation counts as one invite.'}>
                                    Evaluation Invites <InfoCircleOutlined />
                                </Popover>
                                <Button icon={<RocketOutlined />} size="small" onClick={toggleDrawer}>
                                    Reload
                                </Button>
                            </Flex>}
                            value={profile.available_invites}
                        />
                        {(profile.available_invites <= 0) && <Alert message='You have no more invitations left.' type="error" />}
                    </Card>
                </Col>
                <Col span={24}>
                    <Card title='Completed Evaluations'>
                        <Table
                            pagination={false}
                            dataSource={completedEvaluations}
                            columns={[
                                {
                                    title: 'Name',
                                    dataIndex: 'name',
                                    render: (_, record) => (
                                        <>{record?.evaluations?.name}</>
                                    ),
                                },
                                {
                                    title: 'Completed At',
                                    dataIndex: 'responded_at',
                                    render: (text) => {
                                        const date = new Date(text);
                                        return date.toLocaleString();
                                    }
                                }
                            ]}
                        />
                    </Card>
                </Col>
            </Row>
            <Drawer
                title={<><RocketOutlined /> Reload</>}
                onClose={toggleDrawer}
                open={open}
                destroyOnClose
            >
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <Card>
                            <Statistic title='' value={10} suffix='Evaluations' />
                            <Typography.Text type="secondary">Good for: 10 employees for one annual review.</Typography.Text>
                            <Button block href={`https://buy.stripe.com/9AQ3dk4k5aZZ53i003?client_reference_id=${profile.id}`} target="_blank">
                                $1
                            </Button>
                        </Card>
                    </Col>
                    <Col span={24}>
                        <Badge.Ribbon text="Most Popular">
                            <Card>
                                <Statistic title='10% Discount' value={100} suffix='Evaluations' />
                                <Typography.Text type="secondary">Good for: 25 employees for 4 quarterly reviews.</Typography.Text>
                                <Button type="primary" block href={`https://buy.stripe.com/dR69BIdUFc430N2dQS?client_reference_id=${profile.id}`} target="_blank">
                                    $9
                                </Button>
                            </Card>
                        </Badge.Ribbon>
                    </Col>
                    <Col span={24}>
                        <Card>
                            <Statistic title='20% Discount' value={1000} suffix='Evaluations' />
                            <Typography.Text type="secondary">Good for: 250 employees for 4 quarterly reviews.</Typography.Text>
                            <Button block href={`https://buy.stripe.com/aEU29g6sd2tt2Va4gh?client_reference_id=${profile.id}`} target="_blank">
                                $80
                            </Button>
                        </Card>
                    </Col>
                    <Col span={24}>
                        <Badge.Ribbon text="Best Value">
                            <Card>
                                <Statistic title='30% Discount' value={10000} suffix='Evaluations' />
                                <Typography.Text type="secondary">Good for: 250 employees for 4 quarterly reviews for 10 years.</Typography.Text>
                                <Button block href={`https://buy.stripe.com/dR69BIeYJ3xxgM08ww?client_reference_id=${profile.id}`} target="_blank">
                                    $700
                                </Button>
                            </Card>
                        </Badge.Ribbon>
                    </Col>
                </Row>
            </Drawer>
        </>
    )

}

export default Account;
